//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { retrieveButter,
         getDictionary,
         getLang } from '~/utils/butterUtils'
import {event} from 'vue-gtag'

export default {
  head () {
    return {
      meta: [
        {
          hid: 'GPP',
          name: 'robots',
          content: 'noindex'
        }
      ]
    }
  },
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/index.vue asyncData ')
  },
  data () {
    return {
      selectedState: null
    }
  },
  computed: {
    isStudentSite () {
      return process.env.isStudentSite === 'true' || this.$store.state.isSimulatedStudentSite
    },
    lang () {
      return getLang(this.$i18n.locale)
    },
    image () {
      return this.butterVFAGraphics ? this.butterVFAGraphics.landing_page_gpp : null
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterVFAGraphics () {
      return this.$store.state.butterVFAGraphics
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
  },
  methods: {
    anonymousStart () {
      this.$buefy.toast.open({
        message: this.dict.D00,
        type: "is-success",
        duration: 8000,
      });
      sessionStorage.removeItem('sessionstoragephones')
      this.$store.commit("clearVoterData");
      this.$store.dispatch("userdata/clearVoterData");
      event('gpp_start')
      this.$router.push(
        this.localePath({
          name: "request-stage",
          params: { stage: "your-information" },
        })
      );
    },
  },
  mounted () {
    this.$store.commit('saveSelectedState', '')
    localStorage.removeItem('localstoragevotingstate')
  },
}
